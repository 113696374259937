import { t } from 'i18next'
import { useDialog } from '~/components/UiKit'
import { DuplicationError } from '~/utils/fetcher'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('app:manifest:update', p => {
        // eslint-disable-next-line no-console
        console.log('app:manifest:update', p)
    })

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        handler(error)
    }

    nuxtApp.hook('vue:error', (error, instance, info) => {
        handler(error)
    })

    function handler(err: any) {
        if (err.handled) {
            return
        }
        err.handled = true

        if (err instanceof DuplicationError) {
            const lang = err.lang ? `[${err.lang.toUpperCase()}] ` : ''
            const val = err.val.length > 50 ? `${err.val}...` : err.val
            useDialog().open({
                title: t('@rejected'),
                content: `
                <div class="text-white my-2">${lang}«${val}»</div>
                ${err.tableLabel && err.fieldLabel ? `<div class="text-white my-2">[${t(err.tableLabel)} / ${t(err.fieldLabel)}]</div>` : ''}
                <div class="text-white my-2">${t('@uniqControlMessageExists')}</div>
                ${err.link ? `<div class="my2"><a href="${err.link}" target="_blank" class="text-white">${err.link}</a></div>` : ''}
                `,
                duration: 12_000,
                type: 'error',
            })
            return
        }

        const errorText = err.message
        if (errorText) {
            useAppError().show(errorText, t('@error'))
            console.error(err)
        }
    }
})
