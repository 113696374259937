<template>
    <div class="m-8">
        <RLoading v-if="error?.message === 'SoftReload'" />
        <div v-else-if="error?.statusCode === 404">
            <h1>⛔ Запись не найдена</h1>
            <hr class="my-4">
            <div class="flex gap-8">
                <a href="#" @click="$router.go(-1)">Вернуться назад</a>
                <a href="/">Перейти на главную</a>
            </div>
        </div>
        <div v-else>
            <h1>⛔ Произошла ошибка</h1>
            Попробуйте повторить действие через 5 минут.
            <hr class="my-4">
            <pre>{{ error }}</pre>
            <hr class="my-4">
            <div class="flex gap-8">
                <a href="#" @click="clearError()">Обновить</a>
                <a href="#" @click="$router.go(-1)">Вернуться назад</a>
                <a href="/">Перейти на главную</a>
            </div>
        </div>
    </div>
</template>

<script setup>
const error = useError()
</script>
