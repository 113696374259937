import { useNuxtApp } from '#app'
import { defineStore } from 'pinia'
import type { TStatus } from '~/types'

export interface AppLang {
    id: string
    sid: number
    code: string
    name: string
    nameEn: string
    status: TStatus
    uiUsage: boolean
    dataUsage: boolean
    localisationRule: string
}

export type AppTitle = (({ name: string, classes?: string }[]) | (string[]) | string)

interface AppStore {
    title: AppTitle
    langUI: string
    langData: string
    appLanguages: AppLang[]
}

export const useAppStore = defineStore('app', {
    state: (): AppStore => ({
        title: 'Referus',
        langUI: 'en',
        langData: 'en',
        appLanguages: [],
    }),
    actions: {
        setTitle(title: AppTitle) {
            this.title = title
            document.title = Array.isArray(title) ? title.map(i => typeof i === 'string' ? i : i.name).join(' ') : title
        },
        setLangUI(lang: string) {
            const app = useNuxtApp()
            const i18nextInstance = app.$i18next as any
            document.querySelector('html')?.setAttribute('lang', lang)
            if (i18nextInstance?.language === lang) {
                this.langUI = lang
                return
            }
            i18nextInstance?.changeLanguage(lang).then(() => {
                localStorage.setItem('appLang', lang)
            })
            return useFetchCall('/userLanguageUISet', { lang }, { background: true }).then(() => (this.langUI = lang)).catch()
        },
        async reloadTranslations() {
            const app = useNuxtApp()
            const i18nextInstance = app.$i18next as any
            await i18nextInstance.reloadResources([this.langUI])
        },
        setLangData(lang: string) {
            if (this.langData === lang) {
                return
            }
            return useFetchCall('/userLanguageDataSet', { lang }, { background: true }).then(() => (this.langData = lang)).catch()
        },
        async getLanguages() {
            if (this.appLanguages.length) {
                return this.appLanguages
            }
            this.appLanguages = await useFetchCall('/languagesList')

            // ENV использовать нельзя - влияет на кэш сборки, да и получить название окружения затруднительно
            if (location.hostname.match(/localhost|alfa.referus.ru/)) {
                this.appLanguages.unshift(
                    {
                        id: 'dev',
                        sid: 0,
                        code: 'dev',
                        name: 'dev',
                        nameEn: 'Developer',
                        status: 'active',
                        uiUsage: true,
                        dataUsage: false,
                        localisationRule: 'dev',
                    },
                )
            }
            return this.appLanguages
        },

        getLangByCode(code: string) {
            let str = code.toUpperCase()
            const lang = this.appLanguages.find(i => i.code === code)
            if (lang) {
                str += ` (${lang.name})`
            }
            return str
        },
    },
})
